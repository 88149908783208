import React, { useEffect } from 'react';
import PropTypes from 'prop-types/prop-types';
import { withRouter } from 'react-router';
import Footer from './Components/Footer';
import ErklaerVideoComponent from './Components/ErklaerVideo';
import Button from '../../Components/Button';

/**
 * ErklaerVideo()
 * @returns {*}
 * @constructor
 */
function ErklaerVideo(props) {
  const { history } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="d-flex flex-grow-1 flex-column" style={{ margin: -40, backgroundColor: '#F1F1F2' }}>
      <div className="FullPageBox Light">
        <div className="container">
          <h2 className="hk-text-primary">
            Unser Erklärvideo
          </h2>

          <p style={{ marginBottom: 40 }}>
            Erfahren Sie in diesem kurzen Video, wie hey.kitchen für Küchenstudios und Möbelhäuser funktioniert.
          </p>

          <ErklaerVideoComponent />

          <p style={{ marginTop: 50 }}>
            Sie sind interessiert und möchten hey.kitchen ausprobieren?
          </p>
          <p>
            Dann registrieren Sie sich kostenlos hier und buchen Sie Ihre Montage auf unserer Seite.
            Wir kümmern uns um den Rest.
          </p>
          <p style={{ textAlign: 'center', marginTop: 40, marginBottom: 40 }}>
            <Button onClick={() => history.push('/register')}>
              Jetzt kostenos registrieren
            </Button>
          </p>
          <p>
            Haben Sie noch Fragen?
            <br />
            Dann kontaktieren Sie uns. Wir helfen Ihnen gerne.
          </p>
          <p>
            <address style={{ margin: 0 }}><strong>hey.kitchen GmbH</strong></address>
            <address style={{ margin: 0 }}>Rennweg 60</address>
            <address style={{ margin: 0 }}>56626 Andernach</address>

            <address style={{ margin: 0 }}>+49 2266 478 9296</address>
            <address style={{ margin: 0 }}>kontakt@hey.kitchen</address>
            <address style={{ margin: 0 }}>www.hey.kitchen</address>
          </p>
        </div>
      </div>

      <Footer type="Dark" />
    </div>
  );
}

ErklaerVideo.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(ErklaerVideo);
